import CalendarIcon from 'lib/icons/calendar';
import CompanyIcon from 'lib/icons/company';
import ProfileIcon from 'lib/icons/profile';
import PayrollIcon from 'lib/icons/payroll';
import { AccountType } from 'lib/types/user';
import ReportingIcon from '@/lib/icons/reporting';

import DocumentIcon from 'lib/icons/document';

const isDocumentsTabEnabled =
  process.env.NEXT_PUBLIC_FEATURE_DOCUMENTS_TAB === 'true';

export const menuElements = [
  {
    title: 'My profile',
    href: '/profile',
    Icon: ProfileIcon,
    permissions: [
      AccountType.SuperAdmin,
      AccountType.Admin,
      AccountType.Employee,
      AccountType.PayrollAdmin,
    ],
  },
  {
    title: 'Company',
    href: '/company',
    Icon: CompanyIcon,
    permissions: [
      AccountType.SuperAdmin,
      AccountType.Admin,
      AccountType.Employee,
      AccountType.PayrollAdmin,
    ],
  },
  {
    title: 'Calendar',
    href: '/calendar',
    Icon: CalendarIcon,
    permissions: [
      AccountType.SuperAdmin,
      AccountType.Admin,
      AccountType.Employee,
      AccountType.PayrollAdmin,
    ],
  },
  ...(isDocumentsTabEnabled
    ? [
        {
          title: 'Documents',
          href: '/documents',
          Icon: DocumentIcon,
          permissions: [
            AccountType.SuperAdmin,
            AccountType.PayrollAdmin,
            AccountType.Admin,
            // TEMP FOR TESTING
            // AccountType.Employee,
          ],
        },
      ]
    : []),
  {
    title: 'Reporting',
    href: '/reports',
    Icon: ReportingIcon,
    permissions: [
      AccountType.SuperAdmin,
      AccountType.PayrollAdmin,
      AccountType.Admin,
    ],
  },
  {
    title: 'Payroll',
    href: '/payroll',
    Icon: PayrollIcon,
    permissions: [AccountType.SuperAdmin, AccountType.PayrollAdmin],
  },
];
