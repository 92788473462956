import { IconProps } from 'lib/types';

export default function DocumentIcon(props: IconProps) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M13.7143 0C15.9234 0 17.7143 1.79086 17.7143 4V18C17.7143 20.2091 15.9234 22 13.7143 22H4C1.79086 22 0 20.2091 0 18V4C0 1.79086 1.79086 0 4 0H13.7143ZM13.7143 2H4C2.89543 2 2 2.89543 2 4V18C2 19.1046 2.89543 20 4 20H13.7143C14.8189 20 15.7143 19.1046 15.7143 18V4C15.7143 2.89543 14.8189 2 13.7143 2ZM11.7143 12.8571C12.2666 12.8571 12.7143 13.3049 12.7143 13.8571C12.7143 14.37 12.3282 14.7927 11.8309 14.8504L11.7143 14.8571H6C5.44772 14.8571 5 14.4094 5 13.8571C5 13.3443 5.38604 12.9216 5.88338 12.8639L6 12.8571H11.7143ZM11.7143 10C12.2666 10 12.7143 10.4477 12.7143 11C12.7143 11.5128 12.3282 11.9355 11.8309 11.9933L11.7143 12H6C5.44772 12 5 11.5523 5 11C5 10.4872 5.38604 10.0645 5.88338 10.0067L6 10H11.7143ZM9.57143 7.14286C10.1237 7.14286 10.5714 7.59057 10.5714 8.14286C10.5714 8.65569 10.1854 9.07836 9.68805 9.13613L9.57143 9.14286H6C5.44772 9.14286 5 8.69514 5 8.14286C5 7.63002 5.38604 7.20735 5.88338 7.14958L6 7.14286H9.57143Z" />
    </svg>
  );
}
